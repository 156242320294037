import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TopbarService } from '../topbar.service';
import { AuthService } from '@kodit/core/services';
import { Router } from '@angular/router';
import { Sidebar } from 'primeng/sidebar';
import { DynamicDialogConfig, DynamicDialogService } from '@kodit/core/shared-ui';
import { DialogSize } from 'libs/core/shared-ui/src/lib/dialogs/dynamic-dialog/dynamicdialog-config';
import { VerzijaSoftveraDijalogComponent } from '../../footer/verzija-softvera-dijalog/verzija-softvera-dijalog.component';

@Component({
  selector: 'kodit-profil-sidebar',
  templateUrl: './profil-sidebar.component.html',
  styleUrls: ['./profil-sidebar.component.scss'],
})
export class ProfilSidebarComponent implements OnInit, OnDestroy {
  private _subs: Subscription = new Subscription();
  showSidebar: boolean;
  clientPackageId: string;
  el: any;
  showcloseIcon = false;
  @ViewChild('ProfilSidebar') sidebarRef: Sidebar;
  /** Configurations */
  dialogConfig: DynamicDialogConfig = new DynamicDialogConfig(DialogSize.SMALL);

  constructor(
    private service: TopbarService,
    private _authService: AuthService,
    private _dialogService: DynamicDialogService
  ) {}

  ngOnInit(): void {
    this._subs.add(
      this.service.getShowProfileSidebar.subscribe((show) => {
        this.showSidebar = show;
      })
    );
  }

  onHide() {
    this.service.hideProfileSidebar();
    this._destroy();
  }

  onShow() {
    //this.service.hideProfileSidebar();
  }

  logOff() {
    this._authService.logout();
  }

  private _destroy() {
    if (this.sidebarRef) {
      this.service.hideProfileSidebar();
      this.sidebarRef.destroyModal();
    }
  }

  openVerzijaSoftveraDijalog() {
    this.dialogConfig.hideSubmit = true;
    this.dialogConfig.customCancelButton = {
      icon: 'far fa-window-close',
      label: 'Zatvori',
    };
    this.dialogConfig.maximisable = false;
    this.dialogConfig.header = 'Informacije o softveru';
    this._dialogService.open(
      VerzijaSoftveraDijalogComponent,
      this.dialogConfig
    );
  }

  ngOnDestroy() {
    this._destroy();
    this.service.hideProfileSidebar();
    this._subs.unsubscribe();
  }
}
