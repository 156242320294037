import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { apiBaseUrlClean } from 'apps/core/src/environments/environment';

/** Moduli */
import { CoreSharedModule } from 'libs/core/shared/src';
import { TooltipModule } from 'primeng/tooltip';
import { BreadcrumbModule as XngBreadcrumb } from 'xng-breadcrumb';

/** PrimeNg */
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

/** Components */
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopbarComponent } from './topbar/topbar.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { MenuComponent } from './menu/menu.component';
import { CoreAppComponent } from './core-app/core-app.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { ErrorHandlerInterceptorService } from '@kodit/core/services';
// import { DashboardCardComponent } from './dashboard/dashboard-card/dashboard-card.component';
import { KorisnikTenantDropdownComponent } from './ui/korisnik-tenant-dropdown/korisnik-tenant-dropdown.component';
import { PromenaFirmeLoaderComponent } from './promena-firme-loader/promena-firme-loader.component';
import { NotificationItemComponent } from './topbar/notification-item/notification-item.component';
import { PrometChartBarComponent } from './dashboard/promet-chart-bar/promet-chart-bar.component';
import { TopNStrankiChartDonutComponent } from './dashboard/top-n-stranki-chart-donut/top-n-stranki-chart-donut.component';
import { TopNStrankiTableComponent } from './dashboard/top-n-stranki-chart-donut/top-n-stranki-table/top-n-stranki-table.component';
import { DashboardPrometCardsComponent } from './dashboard/dashboard-promet-cards/dashboard-promet-cards.component';
import { DashboardPrometCardComponent } from './dashboard/dashboard-promet-cards/dashboard-promet-card/dashboard-promet-card.component';
import { PrviLoginKompanijaFormComponent } from './welcome-cards/welcome-kompanija-card/prvi-login-kompanija-form/prvi-login-kompanija-form.component';
import { WelcomeMessageComponent } from './welcome-cards/welcome-message/welcome-message.component';
import { WelcomeKompanijaCardComponent } from './welcome-cards/welcome-kompanija-card/welcome-kompanija-card.component';
import { WelcomeKorisnikCardComponent } from './welcome-cards/welcome-korisnik-card/welcome-korisnik-card.component';
import { SrednjiKursZaValuteComponent } from './topbar/srednji-kurs-za-valute/srednji-kurs-za-valute.component';
import { TenantNameComponent } from './topbar/tenant-name/tenant-name.component';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { GlobalFilterInterceptorService } from '../../../services/src/lib/interceptors/global-filter-interceptor.service';
import { ResetTokenTimerInterceptorService } from '../../../services/src/lib/interceptors/reset-token-timer-interceptor.service';
import { TawkChatComponent } from './ui/tawk-chat/tawk-chat.component';
import { JoyrideModule } from 'ngx-joyride';
import { TutorialItemComponent } from './topbar/tutorial-item/tutorial-item.component';
import { RefreshTokenConfirmationDialogComponent } from './ui/refresh-token-confirmation-dialog/refresh-token-confirmation-dialog.component';
import { SidebarModule } from 'primeng/sidebar';
import { IzlogujUpozorenjeDialogComponent } from './ui/izloguj-upozorenje-dialog/izloguj-upozorenje-dialog.component';
import { ProgressItemComponent } from './topbar/progress-item/progress-item.component';
import { GlobalNotificationComponent } from './ui/global-notification/global-notification.component';
import { DugovanjeNotificationComponent } from './ui/global-notification/dugovanje-notification/dugovanje-notification.component';
import {InitComponent} from "./init/init.component";
import { VerzijaSoftveraDijalogComponent } from './footer/verzija-softvera-dijalog/verzija-softvera-dijalog.component';
import {
  SertifikatFiskalnogRacunaInterceptorService
} from "../../../services/src/lib/interceptors/sertifikat-fiskalnog-racuna-interceptor.service";
import { PodesavanjaSidebarComponent } from './topbar/podesavanja-sidebar/podesavanja-sidebar.component';
import { NotifikacijeSidebarComponent } from './topbar/notifikacije-sidebar/notifikacije-sidebar.component';
import { ProfilSidebarComponent } from './topbar/profil-sidebar/profil-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // CoreAuthModule,
    // JwtModule,
    CoreSharedModule,
    HttpClientModule,
    TooltipModule,
    BreadcrumbModule,
    XngBreadcrumb,
    ToastModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    SidebarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          //('getter token: ' + localStorage.getItem('eKompanija_token'));
          return localStorage.getItem('eKompanija_token');
        },
        allowedDomains: [apiBaseUrlClean],
        disallowedRoutes: [],
      },
    }),
    JoyrideModule.forRoot(),
  ],
    declarations: [
        FooterComponent,
        DashboardComponent,
        TopbarComponent,
        AppLayoutComponent,
        MenuItemComponent,
        MenuComponent,
        CoreAppComponent,
        LoginLayoutComponent,
        KorisnikTenantDropdownComponent,
        PromenaFirmeLoaderComponent,
        // DashboardCardComponent,
        NotificationItemComponent,
        PrometChartBarComponent,
        TopNStrankiChartDonutComponent,
        TopNStrankiTableComponent,
        DashboardPrometCardsComponent,
        DashboardPrometCardComponent,
        PrviLoginKompanijaFormComponent,
        WelcomeMessageComponent,
        WelcomeKompanijaCardComponent,
        WelcomeKorisnikCardComponent,
        TenantNameComponent,
        SrednjiKursZaValuteComponent,
        TawkChatComponent,
        TutorialItemComponent,
        RefreshTokenConfirmationDialogComponent,
        IzlogujUpozorenjeDialogComponent,
        ProgressItemComponent,
        GlobalNotificationComponent,
        DugovanjeNotificationComponent,
        InitComponent,
        VerzijaSoftveraDijalogComponent,
        PodesavanjaSidebarComponent,
        NotifikacijeSidebarComponent,
        ProfilSidebarComponent
    ],
  exports: [
    FooterComponent,
    DashboardComponent,
    CoreAppComponent,
    AppLayoutComponent,
    LoginLayoutComponent,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    //CoreService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: coreInit,
    //   deps: [CoreService],
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResetTokenTimerInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalFilterInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SertifikatFiskalnogRacunaInterceptorService,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreCoreUiModule {
  constructor(@Optional() @SkipSelf() core: CoreCoreUiModule) {
    if (core) {
      throw new Error(
        'You should import CoreCoreUiModule module only in the root module'
      );
    }
  }
}
