<!-- Template za joyride step 2(topbar) -->
<ng-template #topbarJoyride>
  <p>Na vrhu stranice se nalazi topbar</p>
</ng-template>

<!-- Template za joyride slanjeNaSefVideo step -->
<ng-template #slanjeNaSefContent>
  <div
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative; width: 50vw"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <iframe
        allow="autoplay; fullscreen"
        allowtransparency="true"
        class="wistia_embed"
        frameborder="0"
        height="100%"
        msallowfullscreen
        name="wistia_embed"
        scrolling="no"
        src="https://fast.wistia.net/embed/iframe/rmyla29eqb?seo=true&videoFoam=true"
        title="slanje_na_sef Video"
        width="100%"
      ></iframe>
    </div>
  </div>
  <script async src="https://fast.wistia.net/assets/external/E-v1.js"></script>
</ng-template>

<!-- Template za joyride slanjeNaEmailVideo step -->
<ng-template #slanjeNaMailContent>
  <div
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative; width: 50vw"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <iframe
        allow="autoplay; fullscreen"
        allowtransparency="true"
        class="wistia_embed"
        frameborder="0"
        height="100%"
        msallowfullscreen
        name="wistia_embed"
        scrolling="no"
        src="https://fast.wistia.net/embed/iframe/bkkl5cx36g?seo=true&videoFoam=true"
        title="slanje_na_email Video"
        width="100%"
      ></iframe>
    </div>
  </div>
  <script async src="https://fast.wistia.net/assets/external/E-v1.js"></script>
</ng-template>

<!-- Template za joyride pravljenjeDuplikataVideo step -->
<ng-template #pravljenjeDuplikataContent>
  <div
    class="wistia_responsive_padding"
    style="padding: 56.25% 0 0 0; position: relative; width: 50vw"
  >
    <div
      class="wistia_responsive_wrapper"
      style="height: 100%; left: 0; position: absolute; top: 0; width: 100%"
    >
      <iframe
        allow="autoplay; fullscreen"
        allowtransparency="true"
        class="wistia_embed"
        frameborder="0"
        height="100%"
        msallowfullscreen
        name="wistia_embed"
        scrolling="no"
        src="https://fast.wistia.net/embed/iframe/vr1at1iurr?seo=true&videoFoam=true"
        title="duplikat_fakture Video"
        width="100%"
      ></iframe>
    </div>
  </div>
  <script async src="https://fast.wistia.net/assets/external/E-v1.js"></script>
</ng-template>

<kodit-loader [content]="content" [showLoader]="showLoadingPage"></kodit-loader>

<kodit-blockable-div #content>
  <div
    (click)="onLayoutClick()"
    [attr.data-theme]="colorScheme"
    [class]="colorScheme === 'light' ? menuTheme : ''"
    [ngClass]="{
      'layout-overlay': menuMode === 'overlay',
      'layout-static': menuMode === 'static',
      'layout-slim': menuMode === 'slim',
      'layout-sidebar-dim': colorScheme === 'dim',
      'layout-sidebar-dark': colorScheme === 'dark',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive':
        staticMenuDesktopInactive && menuMode === 'static',
      'p-input-filled': inputStyle === 'filled',
      'p-ripple-disabled': !ripple
    }"
    class="layout-wrapper"
  >
    <div
      [stepContent]="topbarJoyride"
      joyrideStep="secondStep"
      style="position: sticky; top: 0px; z-index: 996; border-bottom: 2px solid #EAECF0;"
      title="Topbar"
      title="Topbar"
    >
      <kodit-topbar></kodit-topbar>
    </div>

    <div class="layout-content-wrapper">
      <div
        *ngIf="!jePrviLoginKompanije && !jePrviLoginKorisnika"
        class="layout-content"
      >
        <joyrideStep
          [stepContent]="slanjeNaSefContent"
          joyrideStep="slanjeNaSefStep"
          stepPosition="center"
          title="Slanje fakture na SEF"
        >
        </joyrideStep>

        <joyrideStep
          [stepContent]="slanjeNaMailContent"
          joyrideStep="slanjeNaEmailStep"
          stepPosition="center"
          title="Slanje fakture na E-mail"
        >
        </joyrideStep>

        <joyrideStep
          [stepContent]="pravljenjeDuplikataContent"
          joyrideStep="pravljenjeDuplikataStep"
          stepPosition="center"
          title="Pravljenje duplikata fakture"
        >
        </joyrideStep>

        <ng-container [ngTemplateOutlet]="obavestenje"></ng-container>

        <div *ngIf="isSefActiveDto?.isActive == false" class="p-mb-3 p-fluid">
          <p-message
            severity="warn"
            text="Sef nije u funkciji! Sve akcije vezane za sef su trenutno nedostupne."
          ></p-message>
        </div>

        <!-- <div class="p-mb-3 p-d-flex p-jc-between">
          <xng-breadcrumb
            [preserveFragment]="false"
            [separator]="separatorTemplate"
          >
            <ng-container
              *xngBreadcrumbItem="
                let breadcrumb;
                let info = info;
                let first = first
              "
            >
              <ng-container>{{ breadcrumb }}</ng-container>
            </ng-container>
          </xng-breadcrumb>
          <div *ngIf="shouldDisplayFiskalnaKasaStavkeRacunaTitle" class="fiskalna-kasa-stavke-title-card">
            <div class="p-flex align-items-start justify-content-center fiskalna-kasa-stavke-title">
              STAVKE RAČUNA
            </div>
          </div>
        </div> -->

        <router-outlet></router-outlet>
      </div>

      <div
        *ngIf="jePrviLoginKompanije || jePrviLoginKorisnika"
        class="layout-content"
      >
        <kodit-welcome-kompanija-card
          *ngIf="jePrviLoginKompanije"
        ></kodit-welcome-kompanija-card>
        <kodit-welcome-korisnik-card
          *ngIf="jePrviLoginKorisnika"
        ></kodit-welcome-korisnik-card>
      </div>
      <kodit-init></kodit-init>
      <!-- <kodit-footer></kodit-footer> -->
    </div>

    <kodit-menu></kodit-menu>

    <div class="layout-mask modal-in"></div>

    <p-toast position="bottom-center"></p-toast>

    <p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

    <!-- <kodit-tawk-chat></kodit-tawk-chat> -->


    <div class="chat" onclick="this.classList.toggle('active')">
      <div class="background"></div>
      <svg class="chat-bubble" width="100" height="100" viewBox="0 0 100 100">
        <g class="bubble">
          <path class="line line1" d="M 30.7873,85.113394 30.7873,46.556405 C 30.7873,41.101961
          36.826342,35.342 40.898074,35.342 H 59.113981 C 63.73287,35.342
          69.29995,40.103201 69.29995,46.784744" />
          <path class="line line2" d="M 13.461999,65.039335 H 58.028684 C
            63.483128,65.039335
            69.243089,59.000293 69.243089,54.928561 V 45.605853 C
            69.243089,40.986964 65.02087,35.419884 58.339327,35.419884" />
        </g>
        <circle class="circle circle1" r="1.9" cy="50.7" cx="42.5" />
        <circle class="circle circle2" cx="49.9" cy="50.7" r="1.9" />
        <circle class="circle circle3" r="1.9" cy="50.7" cx="57.3" />
      </svg>
    </div>


    <kodit-rating *ngIf="showRating"></kodit-rating>
  </div>
</kodit-blockable-div>

<ng-template #separatorTemplate>
  <i class="fa-regular fa-angle-right"></i>
</ng-template>

<ng-template #obavestenje>
  <kodit-global-notification></kodit-global-notification>
</ng-template>

<kodit-podesavanja-sidebar></kodit-podesavanja-sidebar>
<kodit-notifikacije-sidebar></kodit-notifikacije-sidebar>
<kodit-profil-sidebar></kodit-profil-sidebar>
